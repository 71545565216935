import { navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import RTLayout from '../../components/global/layout/layout';
import * as style from './completed.module.scss';

const RTCompleted: FC<any> = ({ pageContext }) => {
  const [type, setType] = useState<string | null>('');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setType(searchParams.get('type'));
    setTimeout(() => {
      navigate('/');
    }, 10000);
  }, []);

  const backgroundImage = pageContext?.successBackgroundImage
    ? pageContext?.successBackgroundImage[0].url
    : require('../../assets/images/riot_desktop_background.jpg').default;
  const message = (type: string): JSX.Element | undefined => {
    switch (type) {
      case 'small':
        return (
          <div
            className='wysiwyg left-text'
            dangerouslySetInnerHTML={{
              __html: pageContext.smallTournamentFormSuccessMessage,
            }}
          ></div>
        );
      default:
        return (
          <div
            className='wysiwyg left-text'
            dangerouslySetInnerHTML={{
              __html: pageContext.otherTournamentFormSuccessMessage,
            }}
          ></div>
        );
    }
  };

  return (
    <RTLayout
      menuText={pageContext?.uiElements?.menuTxt[0]}
      footerText={pageContext?.uiElements?.footerTxt[0]}
      langModalTranslations={{ title: pageContext?.uiElements?.languageModalTitle, subtitle: pageContext?.uiElements?.languageModalText }}
      cookie={{
        cookieContent: pageContext?.uiElements.cookieContent,
        cookieButtonAccept: pageContext?.uiElements.cookieButtonAccept,
        cookieButtonReject: pageContext?.uiElements.cookieButtonReject,
      }}
    >
      <section className={style.completedContainer}>
        <div className={style.leftContainer}>
          <img src={backgroundImage} alt='' className='absolute top-0 left-0 w-full h-full z-0 object-cover' />
        </div>
        <div className={style.rightContainer}>{type && message(type)}</div>
      </section>
    </RTLayout>
  );
};

export default RTCompleted;
